import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import router from '@/router'

// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useWarehouseList() {
  const { t } = useI18nUtils()
  // Use toast
  const toast = useToast()

  const refStationListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'responsible', label: 'Отвественный', sortable: true },
    { key: 'city', label: 'Город', sortable: true },
    { key: 'address', label: 'Адрес', sortable: true },
    { key: 'comments', label: 'Комментарии', sortable: true },
    { key: 'actions', label: t('Actions') },
  ]

  const queryFields = [
    { label: 'id', whereKeys: ['wm.id', 'wm.name', 'wm.type'], sortKey: 'wm.id' },
    { label: 'responsible', whereKeys: ['wm.resp_user_id', 'u0.firstName', 'u0.lastName', 'u0.email'], sortKey: 'u0.firstName' },
    { label: 'city', whereKeys: ['wm.city'], sortKey: 'wm.city' },
    { label: 'address', whereKeys: ['wm.address'], sortKey: 'wm.address' },
    { label: 'comments', whereKeys: ['wm.comments'], sortKey: 'wm.comments' },
    // { label: 'cost', whereKeys: ['wm.cost'], sortKey: 'wm.cost' },
    // { label: 'quantity', whereKeys: ['wm.quantity'], sortKey: 'wm.quantity' },
    // { label: 'weight', whereKeys: ['wm.weight'], sortKey: 'wm.weight' },
    // { label: 'id'whereKeys w[hereKeys: ['wm.service'], sortKey: 'wm.service' },
    // { label: 'dueDate'whereKeys w[hereKeys: ['wm.dueDate'], sortKey: 'wm.dueDate' },
    // { label: 'client_id', whereKeys: ['wm.client_id'], sortKey: 'wm.client_id' },
    // { label: 'wm_id', whereKeys: ['wm.wm_id'], sortKey: 'wm.wm_id' },
    // { label: 'order_name', whereKeys: ['wm.order_name'], sortKey: 'wm.order_name' },
    // { label: 'firstname', whereKeys: ['sp.firstname'], sortKey: 'sp.firstname' },
    // { label: 'surname', whereKeys: ['sp.surname'], sortKey: 'sp.surname' },
    // { label: 'phone', whereKeys: ['sp.phone'], sortKey: 'sp.phone' },
    // { label: 'name', whereKeys: ['wm.name'], sortKey: 'wm.name' },
    // { label: 'city', whereKeys: ['wm.city'], sortKey: 'wm.city' },
    // { label: 'address', whereKeys: ['wm.address'], sortKey: 'wm.address' },
    // { label: 'type', whereKeys: ['wm.type'], sortKey: 'wm.type' },
  ]

  const perPage = ref(10)
  const totalStations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refStationListTable.value ? refStationListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalStations.value,
    }
  })

  const refetchData = () => {
    refStationListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })
  const q = (val, joiner) => {
    const where = []
    queryFields.forEach(field => {
      field.whereKeys.forEach(column => {
        where.push(`(${column},like,~${val}~)`)
      })
    })

    // eslint-disable-next-line no-plusplus
    // for (let i = 0; i < queryFields.length; i++) {
    //   const columns = queryFields[i].whereKeys
    //   columns.forEach(column => {
    //     where.push(`(${column},like,~${val}~)`)
    //   })
    // }
    return joiner ? `~and(${where.join('~or')})` : where.join('~or')
  }

  const fetchWarehouses = (ctx, callback) => {
    store
      .dispatch('app-warehouse/fetchWarehouses', {
        _join: 'wm.Warehouses,_lj,u0.Users',
        _on1: '(wm.resp_user_id,eq,u0.id)',
        _size: perPage.value,
        _p: currentPage.value,
        _sort: (isSortDirDesc.value === true ? '-' : '') + queryFields.filter(item => item.label === sortBy.value)[0].sortKey,
        _where: (statusFilter.value !== null ? `(wm.type,eq,${statusFilter.value})` : '') + (searchQuery.value !== '' ? q(searchQuery.value, statusFilter.value !== null) : ''),
        _fields: 'wm.id,wm.name,u0.email,wm.city,wm.address,wm.type,wm.comments,wm.resp_user_id,u0.firstName,u0.lastName',
        _draw: 1,
        _tableconstruction_version: 'vertri',
        _zeTable: 'Warehouses',
      })
      .then(response => {
        const { data, recordsTotal } = response.data
        // // console.log('fetching ok', stations)
        callback(data)
        totalStations.value = recordsTotal
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: `Error fetching stations list: ${error}`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStationStatusVariantAndIcon = status => {
    if (status === 'NOK') return { variant: 'warning', icon: 'AlertTriangleIcon' }
    if (status === 'OK') return { variant: 'success', icon: 'CheckCircleIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const deleteWarehouse = id => {
    store.dispatch('app-warehouse/deleteWarehouse', { id })
      .then(response => {
        if (response.data.code === 1) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          // resetForm()
          refetchData()
        } else {
          throw new Error(response.data.status)
          // purchaseData.value = response.data
        }
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      })
      .finally(() => {
        // asdad
      })
  }

  return {
    fetchWarehouses,
    tableColumns,
    perPage,
    currentPage,
    totalStations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refStationListTable,

    statusFilter,

    resolveStationStatusVariantAndIcon,

    refetchData,
    deleteWarehouse,
    t,
  }
}
