<template>
  <b-sidebar
    id="sidebar-warehouse-edit"
    :visible="isEditWarehouseSidebarActive"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-warehouse-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ `${t('Edit Warehouse')} #${id}` }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <validation-observer
        v-show="!onSubmitProcess.open"
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          v-if="id!=null && warehouse!=null"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Warehouse Name -->
          <validation-provider
            #default="validationContext"
            :name="t('Warehouse Name')"
            rules="required"
          >
            <b-form-group
              :state="getValidationState(validationContext)"
              :label="t('Warehouse Name')"
              label-for="warehouse-name"
            >
              <b-form-input
                id="warehouse-name"
                v-model="warehouse.name"
                autofocus
                trim
                placeholder=""
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Address -->
          <validation-provider
            #default="validationContext"
            :name="t('Address')"
            rules="required"
          >
            <b-form-group
              :state="getValidationState(validationContext)"
              :label="t('Address')"
              label-for="address"
            >
              <b-form-input
                id="address"
                v-model="warehouse.address"
                placeholder="221B Baker Street"
                trim
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- City -->
          <validation-provider
            #default="validationContext"
            :name="t('City')"
            rules="required"
          ><b-form-group
            :state="getValidationState(validationContext)"
            label="City"
            label-for="city"
          >
            <v-select
              v-model="warehouse.city"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="['ANDIJAN','BUKHARA','DJIZZAK','FERGANA','KARAKALPAKISTAN','KASHKADARYA','KHOREZM','NAMANGAN','NAVOI','SAMARKAND','SIRDARYA','SURKHANDARYA','TASHKENT']"
              :clearable="false"
              input-id="city"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            :name="t('Comments')"
            rules=""
          >
            <b-form-group
              :label="t('Comments')"
              label-for="comments"
              :state="getValidationState(validationContext)"
            >
              <b-form-textarea
                id="comments"
                v-model="warehouse.comments"
                type="text"
                :state="getValidationState(validationContext)"
                placeholder=""
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            :name="t('Responsible')"
            rules=""
          >
            <b-form-group
              :label="t('Responsible')"
              label-for="responsible"
              :state="getValidationState(validationContext)"
            >
              <v-select
                ref="refRespOpt"
                v-model="warehouse.resp_user_id"
                :state="getValidationState(validationContext)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="users"
                label="name"
                :reduce="val => val.id"
                input-id="responsible"
                :clearable="false"
              >
                <template #list-header>
                  <li
                    v-if="users.length === 0"
                    class="text-center text-primary my-2"
                  >
                    <b-spinner
                      class="align-middle mr-1"
                    />
                    <strong>Загрузка...</strong>
                  </li>
                </template>
                <template #selected-option="{ name, active }">
                  <b-avatar
                    size="26"
                    :text="avatarText(name)"
                    :variant="`light-${resolveUserAvatarVariant(active)}`"
                  />
                  <span class="ml-50 d-inline-block align-middle">
                    {{ name }}
                  </span>
                </template>
                <template #option="{ name, active }">
                  <b-avatar
                    size="26"
                    :text="avatarText(name)"
                    :variant="`light-${resolveUserAvatarVariant(active)}`"
                  />
                  <span class="ml-50 d-inline-block align-middle">
                    {{ name }}
                  </span>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex justify-content-between align-items-center">
            <label for="wmtype">{{ t('Transit') }}</label>
            <b-form-checkbox
              id="wmtype"
              v-model="warehouse.type"
              switch
            />
          </div>
          <!-- Form Actions -->
          <div
            class="d-flex mt-2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="onSubmitProcess.save"
            >
              <b-spinner
                v-if="onSubmitProcess.save"
                small
                type="grow"
              />
              {{ t('Save') }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              {{ t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
      <div
        v-show="onSubmitProcess.open"
        class="text-center text-primary my-2"
      >
        <b-spinner
          class="align-middle mr-1"
        />
        <strong>{{ t('Загрузка') }}...</strong>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import store from '@/store'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BSpinner, BFormTextarea, BFormCheckbox, BAvatar,
} from 'bootstrap-vue'
import {
  ref, watch, onUnmounted,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import warehouseStoreModule from './warehouseStoreModule'

export default {
  components: {
    BSidebar, BForm, BFormGroup, BSpinner, BFormInput, BFormInvalidFeedback, BButton, vSelect, ValidationProvider, ValidationObserver, BFormTextarea, BFormCheckbox, BAvatar,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditWarehouseSidebarActive',
    event: 'update:is-edit-warehouse-sidebar-active',
  },
  props: {
    isEditWarehouseSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const toast = useToast()
    const { t } = useI18nUtils()

    const onSubmitProcess = ref({
      save: false,
      open: false,
    })

    const WAREHOUSE_APP_STORE_MODULE_NAM = 'app-warehouse'

    // Register module
    if (!store.hasModule(WAREHOUSE_APP_STORE_MODULE_NAM)) store.registerModule(WAREHOUSE_APP_STORE_MODULE_NAM, warehouseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WAREHOUSE_APP_STORE_MODULE_NAM)) store.unregisterModule(WAREHOUSE_APP_STORE_MODULE_NAM)
    })
    const fields = ['name', 'address', 'city', 'comments', 'resp_user_id', 'type']
    const blank = ref({
      name: '',
      address: '',
      city: '',
      comments: '',
      resp_user_id: '',
      type: false,
    })

    const warehouse = ref(JSON.parse(JSON.stringify(blank)))

    const resetuserData = () => {
      warehouse.value = JSON.parse(JSON.stringify(blank))
    }

    const users = ref([])
    store.dispatch('app-warehouse/fetchUsers')
      .then(response => {
        users.value = response.data.records.map(item => {
          const newItem = {
            id: item.id,
            name: `${item.firstName} ${item.lastName}`,
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            active: item.active,
          }
          return newItem
        })
      })

    watch(() => props.isEditWarehouseSidebarActive, val => {
      // // console.log(val)
      if (val === true) {
        onSubmitProcess.value.open = true
        store.dispatch('app-warehouse/fetchWarehouse', {
          _where: `(id,eq,${props.id})`,
          _fields: fields.join(','),
          _tableconstruction_version: 'vertri',
          _zeTable: 'Warehouses',
        })
          .then(response => {
            const obj = response.data.records[0]
            obj.type = obj.type === 'Transit'
            warehouse.value = obj
          })
          .catch(error => {
            if (error.response.status === 404) {
              warehouse.value = undefined
            }
          })
          .finally(() => {
            onSubmitProcess.value.open = false
          })
      }
    })
    // // console.log(Ripple)

    const workableOptions = ref([
      'OK',
      'NOK',
    ])

    const onSubmit = () => {
      onSubmitProcess.value.save = true
      warehouse.value.type = warehouse.value.type ? 'Transit' : null
      store.dispatch('app-warehouse/updateWarehouse', { id: props.id, warehouseData: warehouse.value })
        .then(res => {
          console.log(res.data)
          emit('refetch-data')
          emit('update:is-edit-warehouse-sidebar-active', false)
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно обновили данные станции.',
            },
          })
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ошибка',
              icon: 'XCircleIcon',
              variant: 'error',
              text: error.message,
            },
          })
        })
        .finally(() => {
          onSubmitProcess.value.save = false
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    const resolveUserAvatarVariant = status => {
      // if (status === 'New') return 'primary'
      // // if (status === 'Closed') return 'danger'
      // // if (status === 'Downloaded') return 'secondary'
      // if (status === 'Draft') return 'warning'
      // if (status === 'Sent') return 'info'
      if (status === 1) return 'success'
      return 'secondary'
    }

    return {
      onSubmitProcess,
      warehouse,
      workableOptions,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      t,
      users,
      resolveUserAvatarVariant,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
